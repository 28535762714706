var React = require('react');

export class Icon extends React.Component {
	render() {
		var clsName = "fa fa-" + this.props.icon;
		return (
			<i className={clsName}></i>
		);
	}
}

export class Tag extends React.Component {
	render() {
		return (
			<div className="tags__item">{this.props.children}</div>
		);
	}
}