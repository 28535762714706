var React = require('react');

var CommunitiesList = require('./Communities.jsx').CommunitiesList;
var Button = require('./Button.jsx').Button;
var Icon = require('./Common.jsx').Icon;
var NewsList = require('./News.jsx').NewsList;

export class ClubsPage extends React.Component {
	render() {
		return (
			<div>
				<h1>Все сообщества</h1>
				<CommunitiesList />
			</div>			
		);
	}
}

export class CommunityPage extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			title: "Любители роботов",
			desc: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minus sequi totam sapiente tenetur nisi officia, doloribus modi laborum! Facere consectetur, tenetur velit, sunt illum quaerat sequi placeat optio quis magnam.",			
		}
	}

	render() {
		return (
			<div>
				<div className="breadcrumbs">
					<a href="#/clubs/" className="breadcrumbs__item">Все сообщества</a>
					<div className="breadcrumbs__item">{this.state.title}</div>
				</div>
				<div className="u-teal-bg padding-top-3 padding-bottom-1 padding-horizontal-1 font--size-large">
					<div className="feed">
						<div className="feed__item">
							<img src="/media/img/community_icon.png" alt="Изображение сообщества" className="feed__item__image img--border"/>
							<div className="feed__item__content">
								<div className="feed__item__content__title">{this.state.title}</div>
								<div className="feed__item__content__message">{this.state.desc}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="grid">
					<div className="col-9">
						<NewsList />
					</div>
					<div className="col-3 u-gray-bg padding-1">
						<Button color="blue" icon="icon"><Icon icon="plus" />Подписаться</Button>
						<p>Подписчики: 6</p>

						<h6>Контакты</h6>					
						<ul>
							<li>Администратор: +7845544165</li>
							<li>Модератор: +7845544165</li>
							<li>Реклама: +7845544165</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}