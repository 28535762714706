var React = require('react');

var NewsItem = require('./News.jsx').NewsItem;
var NewsList = require('./News.jsx').NewsList;

export class NewsListPage extends React.Component {
	render() {
		return (
			<div className="grid">
				<div className="col-9">
					<h1> Последние новости </h1>
					<NewsList />
				</div>
				<div className="col-3 u-gray-bg padding-top-3">
					<h6 className="m-gray-fg">ПОТОК НОВОСТЕЙ</h6>
					<ul className="list list--animation-border list--color-teal">
						<li className="list__item list__item--active">Мои подписки</li>
						<li className="list__item">Избранное</li>
						<li className="list__item">Вся лента</li>
					</ul>

					<h6 className="m-gray-fg margin-top-2">СОРТИРОВКА ПО ВРЕМЕНИ</h6>
					<ul className="list list--animation-border list--color-teal">
						<li className="list__item list__item--active">За последнюю неделю</li>
						<li className="list__item">За последний месяц</li>
						<li className="list__item">Все записи</li>
					</ul>
				</div>
			</div>
		);
	}
}

export class NewsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title: null,
			text: null,
			date: null,
			views: 0,
		};
	}

	componentDidMount() {
		console.log('component did mount');
		$.get('/api/news/' + this.props.match.params.id, {}, (response) => {
			console.log('response!');
			console.log(response);
			this.setState({
				title: response.title,
				text: response.text,
				date: response.pub_date,
				views: response.views,
			});
		});
	}

	render() {
		let article = <div className="loading-layout loading-layout--transparent"></div>;
		let aside = <div className="loading-layout loading-layout--transparent"></div>;

		if (this.state.text) {
			article = <article className="margin-2">
				<h1>{this.state.title}</h1>
				<hr/>
				<p>{this.state.text}</p>
			</article>;

			aside = <aside>
				<h6 className="m-gray-fg">АВТОР ПУБЛИКАЦИИ</h6>
				<div className="feed">
					<div className="feed__item">
						<img src="http://localhost:8000/media/img/community_icon.png" className="feed__item__image"/>
						<div className="feed__item__content">
							<div className="feed__item__content__title">Автор публикации</div>
						</div>
					</div>
				</div>

				<div className="align-center">
					<button className="button--color-blue button--icon"><i className="fa fa-plus"></i>Подписаться</button>
					<button className="button--color-red button--icon"><i className="fa fa-star"></i>В избранное</button>
				</div>

				<h6 className="m-gray-fg margin-top-3">ИНФОРМАЦИЯ</h6>
				<p><b>Дата публикации:</b> {this.state.date}</p>
				<p><b>Просмотры:</b> {this.state.views}</p>

				<h6 className="m-gray-fg margin-top-3">КАТЕГОРИИ</h6>
				<div className="tags">
					<div className="tags__item">Новинки 2017</div>
					<div className="tags__item">Робототехника</div>
					<div className="tags__item">Киров</div>
					<div className="tags__item">ВятГУ</div>
					<div className="tags__item">Техноблог</div>
				</div>
			</aside>;
		}

		return (
			<article>
				<div className="breadcrumbs">
					<a href="#/news/" className="breadcrumbs__item">Все новости</a>
					<div className="breadcrumbs__item">{this.state.title}</div>
				</div>				
				<div className="grid">
					<div className="col-9">
						{article}
					</div>
					<div className="col-3 u-gray-bg padding-top-3">
						{aside}
					</div>
				</div>
				
			</article>
		);
	}
}

export class NewsAddPage extends React.Component {
	render() {
		return (
			<form action="" className="form form--fit">
				<h1>Добавление новости</h1>
				<label htmlFor="">Название новости:</label>
				<input type="text" placeholder="Название новости" />

				<label htmlFor="">Содержимое новости:</label>
				<textarea placeholder="Введите новости"></textarea>

				<input type="submit"/>
			</form>
		);
	}
}