var React = require('react');

export class NewsItem extends React.Component {
	render() {
		let title = this.props.title;
		let desc = this.props.desc;
		let id = this.props.id;

		return (
			<div className="news__item">
				<a href={"#/article/" + id} className="news__item__title" tabIndex="0">{title}</a><br/>
				<div className="news__item__date">Дата публикации: 23 октября 2017 | Просмотров: 147 | Комментариев: 3</div>
				<div className="news__item__desc">{desc}</div>
			</div>
		)
	}
}

export class NewsList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			news: null,			
		}

		console.log(this.state);
	}	

	componentDidMount() {
		console.log('component did mount');
		$.get('/api/news/', {}, (response) => {
			console.log('response!');
			console.log(response);
			this.setState({
				news: response
			});
		});
	}

	render() {
		console.log('render news');

		let newsList = <div className="loading-layout loading-layout--transparent"></div>;

		if (this.state.news) {
			newsList = this.state.news.map((current) => 
				<NewsItem key={current.id} id={current.id} title={current.title} desc={current.text} />
			);
		}
		
		return (
			<div className="news">
				{newsList}
			</div>
		);
	}
}