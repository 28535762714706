var React = require('react');

var Button = require('./Button.jsx').Button;
var Icon = require('./Common.jsx').Icon;
var NewsList = require('./News.jsx').NewsList;

class CommunityItem extends React.Component {
	render() {
		return (
			<div className="feed__item">
				<img src="/media/img/community_icon.png" alt="Изображение сообщества" className="feed__item__image"/>
				<div className="feed__item__content">
					<a href={"#/community/" + this.props.key} className="feed__item__content__title">{this.props.title}</a>
					<div className="feed__item__content__message">{this.props.desc}</div>
					<div className="feed__item__content__meta"><Button color="teal" size="small" icon="icon"><Icon icon="plus" />Присоединиться</Button> Участников: {this.props.members}</div>
				</div>
			</div>
		);
	}
}

export class CommunitiesList extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			communities: [
				{
					id: 1,
					title: 'Любители роботов',
					desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet iste voluptas quasi libero ea a minima velit consectetur harum, odit esse hic, natus incidunt animi ab provident accusamus, totam aperiam!',
					members: 6
				},
				{
					id: 2,
					title: 'Arduino',
					desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet iste voluptas quasi libero ea a minima velit consectetur harum, odit esse hic, natus incidunt animi ab provident accusamus, totam aperiam!',
					members: 17
				},
				{
					id: 3,
					title: '#HateRobots',
					desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet iste voluptas quasi libero ea a minima velit consectetur harum, odit esse hic, natus incidunt animi ab provident accusamus, totam aperiam!',
					members: 25
				},
				{
					id: 4,
					title: 'Сообщество юных робототехников',
					desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet iste voluptas quasi libero ea a minima velit consectetur harum, odit esse hic, natus incidunt animi ab provident accusamus, totam aperiam!',
					members: 6
				},
				{
					id: 5,
					title: 'Просто сообщество',
					desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet iste voluptas quasi libero ea a minima velit consectetur harum, odit esse hic, natus incidunt animi ab provident accusamus, totam aperiam!',
					members: 20
				}
			]
		}
	}

	render() {
		var communities = this.state.communities.map((current) => {
			console.log("check community");
			return (<CommunityItem key={current.id} title={current.title} desc={current.desc} members={current.members} />);
		});

		return (
			<div className="feed">
				{communities}
			</div>
		);
	}
}

