var React = require('react');

var Button = require('./Button.jsx').Button;
var Icon = require('./Common.jsx').Icon;
var Tag = require('./Common.jsx').Tag;

export class CategoriesList extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			categories: ['Роботы', 'Программирование', 'Инженерия', 'Клубы по интересам', 'Отечественное']
		}
	}

	render() {
		var categories = this.state.categories.map((current, index) => {
			return (<Tag key={index}>current</Tag>);
		});

		return (
			<div className="tags margin-1">{categories}</div>
		);
	}
}