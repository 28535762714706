var React = require('react');

export class Logo extends React.Component {
	render() {
		return (
			<a href="#"><header className="logo"></header></a>
		);
	}
}

export class Navigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			navigation: [
				{
					id: 1,
					name: "Главная",
					link: "#/",
				},
				{
					id: 2,
					name: "Новости",
					link: "#/news",
				},
				{
					id: 3,
					name: "Сообщества",
					link: "#/clubs",
				},
				{
					id: 4,
					name: "Категории",
					link: "#/categories",
				},
				{
					id: 5,
					name: "О проекте",
					link: "#/about",
				},
			],
			page: 1
		}
	}	

	handleClick(current_id) {
		console.log(current_id);
		let link = this.state.navigation[current_id - 1].link;
		this.setState({
			page: current_id
		});
		location.hash = link;
	}

	componentDidMount() {
		console.log("link did mount");
		console.log(this.profileLink);
	}

	render() {
		let menu = this.state.navigation.map((current) => {
			let clsName = "tabs__item";
			if (current.id == this.state.page) clsName += " tabs__item--active";
			return <a key={current.id} className={clsName} href={current.link} onClick={this.handleClick.bind(this, current.id)}>{current.name}</a>
		});

		return (			
			<div className="grid d-gray-bg">
				<div className="col-8">
					<nav className="tabs tabs--invert tabs--transparent">
						{menu}
					</nav>
				</div>
				<div className="col-4 align-right">
					<div className="grid">
						<div className="col-8"><div className="form form--invert form--fit">
						<input type="search" placeholder="Поиск по сайту..." />
					</div></div>
						<div className="col-4">
							<div className="tabs tabs--invert tabs--transparent">
								<a className="tabs__item" data-dropdown="profileDropdown" ref={(a) => { this.profileLink = a; }}>@apcom</a>
							</div>
						</div>
					</div>	
				</div>		

				<div className="dropdown" id="profileDropdown">
					<div className="dropdown__item">Мой профиль</div>
					<div className="dropdown__item">Мои сообщества</div>
					<div className="dropdown__item">Мои друзья</div>
					<div className="dropdown__item">Мое избранное</div>
					<div className="dropdown__separator"></div>
					<div className="dropdown__item">Настройки</div>
					<div className="dropdown__item">Выйти</div>
				</div>
			</div>
		);
	}		
}