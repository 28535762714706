var React = require('react');

var Button = require('./Button.jsx').Button;

export class AuthPage extends React.Component {
	render() {
		return (
			<div className="form form--fit">
				<h1 className="margin-1">Вход в аккаунт</h1>
				<input type="text" placeholder="Ваш логин" />
				<input type="password" placeholder="Ваш пароль" />
				<Button color="green">Вход</Button>
				<Button>Регистрация</Button>
			</div>
		);
	}
}