var React = require('react');

export class Button extends React.Component {
	render() {
		let color = this.props.color;
		let size = this.props.size;
		let smooth = this.props.smooth;
		let icon = this.props.icon;
		let transparency = this.props.transparency;

		let clsName = "";

		if (color) clsName += " button--color-" + color;
		if (size) clsName += " button--size-" + size;
		if (smooth) clsName += " button--" + smooth;
		if (icon) clsName += " button--" + icon;
		if (transparency) clsName += " button--" + transparency;

		return (
			<button className={clsName}>{this.props.children}</button>
		);
	}
}