var React = require('react');

export class AboutPage extends React.Component {
	render() {
		return (
			<div>
				<h1>О проекте</h1>
				<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias temporibus, facilis possimus blanditiis 
				dolorum earum tempora ipsam esse maxime, sint mollitia voluptate quia ab officiis incidunt veniam atque animi rerum.</p>
			</div>			
		);
	}
}