var React = require('react');

var Logo = require('./Header.jsx').Logo;
var Navigation = require('./Header.jsx').Navigation;
var NewsItem = require('./News.jsx').NewsItem;
var NewsList = require('./News.jsx').NewsList;
var Footer = require('./Footer.jsx').Footer;

export class Application extends React.Component {
	render() {
		return (
			<div className="page__content shadow-5">
				<Logo />
				<Navigation />
				<div className="content">
					{this.props.children}					
				</div>
				<Footer />
			</div>
		);
	}
}