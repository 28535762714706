var React = require('react');

var CategoriesList = require('./Categories.jsx').CategoriesList;

export class CategoriesPage extends React.Component {
	render() {
		return (
			<div>
				<h1>Все категории</h1>
				<CategoriesList />
			</div>			
		);
	}
}