var React = require('react');
var ReactDOM = require('react-dom');
var Router = require('react-router').Router;
var HashRouter = require('react-router-dom').HashRouter;
var Route = require('react-router').Route;
var IndexRoute = require('react-router').IndexRoute;
var browserHistory = require('react-router').browserHistory;

var Application = require('./Application.jsx').Application;
var HomePage = require('./HomePage.jsx').HomePage;
var NewsListPage = require('./NewsListPage.jsx').NewsListPage;
var NewsPage = require('./NewsListPage.jsx').NewsPage;
var NewsAddPage = require('./NewsListPage.jsx').NewsAddPage;
var ClubsPage = require('./ClubsPage.jsx').ClubsPage;
var CategoriesPage = require('./CategoriesPage.jsx').CategoriesPage;
var AboutPage = require('./AboutPage.jsx').AboutPage;
var AuthPage = require('./AuthPage.jsx').AuthPage;
var Community = require('./ClubsPage.jsx').CommunityPage;

// class Application extends React.Component {
// 	render() {
// 		return (
// 			<div className="page__content">
// 				<Logo />
// 				<Navigation />
// 				<h1 className="margin-horizontal-1"> Последние новости </h1>
// 				<NewsList />
// 				<Footer />
// 			</div>
// 		);
// 	}
// }

ReactDOM.render(
	<HashRouter>
		<Application>
			<Route exact path='/' component={HomePage} />
			<Route path='/news-add/' component={NewsAddPage} />
			<Route path='/news/' component={NewsListPage} />
			<Route path='/article/:id' component={NewsPage} />
			<Route path='/clubs' component={ClubsPage} />
			<Route path='/community/:id' component={Community} />
			<Route path='/categories' component={CategoriesPage} />
			<Route path='/about' component={AboutPage} />
			<Route path='/auth' component={AuthPage} />
		</Application>
	</HashRouter>,
	document.getElementById('application')
);